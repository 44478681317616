.AccordionTable {
  position: relative;

  .ui.grid &-menu-left.column, .ui.grid &-menu-right.column {
    display: flex;
  }

  .ui.grid &-menu-right.column {
    flex: 2;
  }

  .ui.grid &-menu-left.column {
    flex: 1;
    min-width: fit-content;
    width: fit-content;
    justify-content: left;
  }

  .header-button {
    .button {
      .icon {
        opacity: 1!important;
      }
    }

    .tertiary.button:hover, .tertiary.button:active, .tertiary.button:focus,
    .button, .button:hover, .button:active, .button:focus {
      box-shadow: none;
      color: white;
    }

    position: absolute;
    right: -4px;
  }

  @media (width <= 768px) {
    .ui.grid {
      flex-direction: column;
    }

    .ui.grid &-menu-left.column {
      width: 100%;
      justify-content: left;
      flex: none;
    }

    .ui.grid &-menu-right.column {
      width: 100%;
      justify-content: center;
    }
  }
}


