<template>
  <div class="UnitAssignmentApp">
    <ErrorMessage :error="error" />
    <SuiButton
      v-if="hasAddUnitPrivilege"
      name="addUnit"
      primary
      icon="plus"
      @click="addUnit"
    >
      WE hinzufügen
    </SuiButton>
    <SuiMenu class="fluid UnitAssignmentApp-filter">
      <SuiMenuItem as="div">
        <div class="ui action input">
          <input
            ref="inputPeopleSearchQuery"
            v-model="peopleSearchQuery"
            placeholder="Bewohner durchsuchen..."
            title="Es kann nach Vorname, Nachname, Firma und Wohnung gesucht werden."
          >
          <SuiButton
            name="clearPeopleSearchQuery"
            title="Suchfeld leeren"
            class="tiny"
            type="button"
            icon="times"
            @click="clearPeopleSearchQuery"
          />
        </div>
      </SuiMenuItem>
      <SuiMenuItem as="div">
        <div class="ui action input">
          <input
            ref="inputUnitsSearchQuery"
            v-model="unitsSearchQuery"
            placeholder="Wohnungen durchsuchen..."
            title="Es kann nach Lage im Gebäude sowie Vorname, Nachname, und Firma der Bewohner gesucht werden."
          >
          <SuiButton
            title="Suchfeld leeren"
            name="clearUnitsSearchQuery"
            class="tiny"
            type="button"
            icon="times"
            @click="clearUnitsSearchQuery"
          />
        </div>
      </SuiMenuItem>
      <SuiMenuItem as="div">
        <SuiDropdown
          v-model="unitOccupiedFilter"
          :options="availableUnitOccupiedFilters.map(filter => ({ value: filter.key, text: filter.displayText }))"
          selection
          fluid
        />
      </SuiMenuItem>
      <SuiMenuItem as="div">
        <SuiDropdown
          v-model="peopleActiveFilter"
          :options="availablePeopleActiveFilters.map(filter => ({ value: filter.key, text: filter.displayText }))"
          selection
          fluid
        />
      </SuiMenuItem>
    </SuiMenu>

    <div class="vl-parent UnitAssignmentApp-splitarea-container">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :opacity="0.7"
        color="#0a0a90"
      />
      <splitpanes
        class="default-theme"
        style="height: 800px"
      >
        <pane :size="25">
          <div class="scroll-container scroll-box">
            <div>
              <PeopleListComponent :people="filteredPeople" />
            </div>
          </div>
        </pane>
        <pane :size="75">
          <div class="scroll-container scroll-box">
            <div class="units-container">
              <div
                v-for="unit in filteredUnits"
                :key="unit.id"
              >
                <UnitComponent
                  :unit="unit"
                  :has-delete-unit-privilege="hasDeleteUnitPrivilege"
                  @result="onResult"
                />
              </div>
            </div>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import PeopleListComponent from './PeopleListComponent.vue'
import UnitComponent from './UnitComponent.vue'
import { useBuildingStore } from '@/stores/BuildingStore'
import { mapState } from 'pinia'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import { SuiMenu, SuiMenuItem, SuiDropdown, SuiButton } from 'vue-fomantic-ui'
import ErrorMessage from '../_partials/Atom/ErrorMessage.vue'

export default {
  components: {
    ErrorMessage,
    SuiMenu,
    SuiMenuItem,
    SuiButton,
    SuiDropdown,
    PeopleListComponent,
    UnitComponent,
    Splitpanes,
    Pane,
    Loading
  },
  props: {
    buildingId: { type: Number, default: null },
    hasAddUnitPrivilege: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasDeleteUnitPrivilege: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  setup (props) {
    const buildingStore = useBuildingStore()
    buildingStore.fill(props.buildingId)

    return {
      buildingStore
    }
  },
  data () {
    return {
      error: null,
      peopleSearchQuery: '',
      unitsSearchQuery: '',
      unitOccupiedFilter: 'allUnits',
      peopleActiveFilter: 'allPeople',
      isLoading: true,
      availableUnitOccupiedFilters: [
        { key: 'allUnits', displayText: 'Alle WE' },
        { key: 'occupiedUnits', displayText: 'Belegte WE' },
        { key: 'emptyUnits', displayText: 'Leere WE' }
      ],
      availablePeopleActiveFilters: [
        { key: 'allPeople', displayText: 'Alle Personen' },
        { key: 'activePeople', displayText: 'aktive Personen' },
        { key: 'inactivePeople', displayText: 'inaktive Personen' }
      ]
    }
  },
  computed: {
    ...mapState(useBuildingStore, {
      buildingData: 'buildingData'
    }),
    filteredPeople () {
      const searchTerms = this.peopleSearchQuery.toLowerCase().split(' ').filter(term => term)

      if (!('people' in this.buildingData)) {
        return []
      }

      return this.buildingData.people.filter(person => {
        if ((this.peopleActiveFilter.value === 'activePeople' && !person.active) ||
            (this.peopleActiveFilter.value === 'inactivePeople' && person.active)
        ) {
          return false
        }

        if (!this.peopleSearchQuery) {
          return true
        }

        const nachname = person.nachname ? person.nachname.toLowerCase() : ''
        const vorname = person.vorname ? person.vorname.toLowerCase() : ''
        const firma = person.firma ? person.firma.toLowerCase() : ''
        const wohnung = person.wohnung ? person.wohnung.toLowerCase() : ''

        return searchTerms.every(term => nachname.includes(term) ||
            vorname.includes(term) ||
            firma.includes(term) ||
            wohnung.includes(term)
        )
      })
    },
    filteredUnits () {
      const searchTerms = this.unitsSearchQuery.toLowerCase().split(' ').filter(term => term)

      return this.buildingData.units.filter(unit => {
        const unitIsOccupied = unit.people && unit.people.length > 0
        if ((this.unitOccupiedFilter.value === 'occupiedUnits' && !unitIsOccupied) ||
            (this.unitOccupiedFilter.value === 'emptyUnits' && unitIsOccupied)
        ) {
          return false
        }

        if (!searchTerms || searchTerms.length === 0) {
          return true
        }

        const mapper = person => {
          const nachname = person.nachname ? person.nachname.toLowerCase() : ''
          const vorname = person.vorname ? person.vorname.toLowerCase() : ''
          const firma = person.firma ? person.firma.toLowerCase() : ''

          return vorname + nachname + firma
        }
        const locationInBuilding = unit.location_in_building ? unit.location_in_building.toLowerCase() : ''
        const people = unit.people && unit.people.length > 0 ? unit.people.map(mapper).join(' ') : ''

        return searchTerms.every(term => locationInBuilding.includes(term) || people.includes(term))
      })
    }
  },
  watch: {
    buildingData (newVal) {
      if (newVal) {
        this.isLoading = false
      }
    }
  },
  methods: {
    onResult (message) {
      if (message.error) {
        this.error = message.error
      } else {
        this.error = null
      }
      this.buildingStore.fill(this.buildingId)
    },
    async addUnit () {
      await this.buildingStore.addUnit(this.buildingId)
    },
    clearPeopleSearchQuery () {
      this.peopleSearchQuery = ''
    },
    clearUnitsSearchQuery () {
      this.unitsSearchQuery = ''
    }
  }
}
</script>

<style scoped>
.scroll-container {
  flex: 1 1 auto;
  flex-wrap: wrap;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 5px;
  margin: 10px;
}

.splitpanes.default-theme .splitpanes__pane {
  background-color: #fff;
}

.units-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 10px;
}

.people-search {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 30px;
}

.units-search {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 30px;
}

.units-search > input[type="text"] {
  width: auto;
  min-width: 50%;
}

.units-search label {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.units-search input[type="radio"] {
  display: inline;
  width: auto;
  min-width: 20px;
  transform: scale(1.5);
  margin-right: 7px;
}

.units-search input[type="radio"]:hover,
.units-search input[type="radio"]:focus {
  box-shadow: none;
}

.UnitAssignmentApp-filter {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

.UnitAssignmentApp-splitarea-container {
  border: 1px solid rgb(222 222 222);
  border-top: 0;
}
</style>
