.Map {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #eef0d5;

  .map {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .map-button-container {
    margin-bottom: 5px;
    position: absolute;
    z-index: 1;
  }
}

