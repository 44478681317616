.c-service-table {
  .ui.table {
    margin-top: 0;
  }

  .td-multi-lines {
    span {
      display: block;
    }
  }
}
