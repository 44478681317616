.EntityCardList {
  &-header {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    color: grey;
  }

  &-info {
    margin-top: 0.3em;
  }

  &-reference,  &-info {
    margin-left: 0.3em;
  }

  span.ui.text {
    line-height: 1.3;
  }
}
