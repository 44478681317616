@import url("var.less");

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.woff2") format('woff2');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Roboto-Bold.woff2") format('woff2');
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url("../fonts/RobotoMono-Bold.woff2") format('woff2');
}

