@element: "site";
@type: "global";

@import url("../../fomantic-ui/theme.config");
@import url("../../var");

.ImageCropper {
  &-preview {
    border-radius: 100em;
    overflow: hidden;
    border: 2px solid @grey;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);

    .cr-viewport {
      border-color: transparent;
    }
  }
}
