.PopupHeader {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 8fr 4fr;
  grid-template-areas: "logo header action";
  gap: 0;

  &-header {
    grid-area: header;
    text-align: center;

    > .ui.header {
      font-size: 1.5rem;
    }
  }

  &-title {
    color: @mutedTextColor;
  }

  &-link {
    margin-left: 0.5rem;
    font-size: 1rem;
  }

  &-logo {
    grid-area: logo;
  }

  &-action {
    grid-area: action;
    text-align: right;
  }

  @media screen and (max-width: @largestMobileScreen) {
    &-action {
      visibility: hidden;
    }
  }

  &-action, &-header {
    > *:first-child {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

main.responsive {
  .PopupHeader {
    @media only screen and (max-width: @largestMobileScreen) {
      grid-template-columns: 1fr 1fr;
      gap: 0.75rem;
      grid-template-areas: "logo action" "header header";

      &-logo {
        > .LogoImage {
          img {
            max-height: 50px;
          }
        }
      }
    }
  }
}
