.DocumentCard {
  &-image:not(&-image--placeholder) {
    position: relative !important;
    padding: 1em !important;

    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-image: var(--thumbnail);
    }
  }

  &-image--placeholder {
    .ui.placeholder .image:not(.header, .ui, .icon) {
      height: 13.5em !important;
      padding-top: 13.5em !important;
    }
  }

  &-header {
    word-break: break-all;
  }

  &-preview {
    @h: calc(100vh - 200px);

    width: 100%;
    max-height: @h;
    max-width: 100%;

    &--full {
      height: @h;
    }

    img {
      margin: auto;
      height: auto !important;
      width: auto !important;
      max-width: inherit !important;
      max-height: inherit !important;
    }

    .ui.embed {
      padding: 0 !important;
      height: @h !important;
    }
  }
}
