.LogoImage {
  display: block;

  a {
    display: inline-block;
  }

  img {
    width: 100%;
    max-width: 188px;
  }
}
