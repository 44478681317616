
.c-task-history {
  display: block;
  margin-top: 1rem;

  #topicTreeSelect {
    align-items: center;
    display: flex;
  }
}
