.EntityLink-reference {
  margin-left: 0.25em;
}
.EntityLink .icon::before {
  content: "\00a0";
  position: relative;
  top: -0.1em;
}
/*

████████╗██╗  ██╗███████╗███╗   ███╗███████╗███████╗
╚══██╔══╝██║  ██║██╔════╝████╗ ████║██╔════╝██╔════╝
   ██║   ███████║█████╗  ██╔████╔██║█████╗  ███████╗
   ██║   ██╔══██║██╔══╝  ██║╚██╔╝██║██╔══╝  ╚════██║
   ██║   ██║  ██║███████╗██║ ╚═╝ ██║███████╗███████║
   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚══════╝╚══════╝

*/
/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element
   specify theme name below
*/
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/* stylelint-disable-next-line at-rule-prelude-no-invalid */
/*******************************
        Import Directives
*******************************/
/* ------------------
       Theme
------------------- */
/* --------------------
   Site Variables
--------------------- */
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/* General */
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Font Awesome Pro only */
/* Font Awesome Pro only */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Flyout */
/* Modal */
/* Nag */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/* -------------------
       Fonts
-------------------- */
/* -------------------
      Base Sizes
-------------------- */
/* This is the single variable that controls them all */
/* The size of page text */
/* -------------------
    Border Radius
-------------------- */
/* See Power-user section below
   for explanation of @px variables
*/
/* -------------------
    Brand Colors
-------------------- */
/* Whenever a color needs to get calculated (screen()/multiply()) out of a base color */
/* --------------
  Page Heading
--------------- */
/* --------------
   Form Input
--------------- */
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/* -------------------
    Focused Input
-------------------- */
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/* -------------------
        Sizes
-------------------- */
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/* -------------------
        Page
-------------------- */
/* -------------------
      Paragraph
-------------------- */
/* -------------------
       Links
-------------------- */
/* -------------------
    Scroll Bars
-------------------- */
/* Inverted */
/* IE needs hex values */
/* Scrolling */
/* -------------------
  Highlighted Text
-------------------- */
/* -------------------
       Loader
-------------------- */
/* -------------------
        Grid
-------------------- */
/* -------------------
     Transitions
-------------------- */
/* -------------------
     Breakpoints
-------------------- */
/* -------------------
      Site Colors
-------------------- */
/* ---  Colors  --- */
/* ---  Light Colors  --- */
/* ---   Neutrals  --- */
/* --- Colored Backgrounds --- */
/* --- Colored Headers --- */
/* --- Colored Text --- */
/* --- Light Colored Text --- */
/* --- Hovered Colored Text --- */
/* --- Colored Border --- */
/* --- Shadows --- */
/* Inverted */
/* Box Shadows */
/* -------------------
     Alpha Colors
-------------------- */
/* -------------------
       Accents
-------------------- */
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/* -------------------
    Emotive Colors
-------------------- */
/* Positive */
/* Negative */
/* Info */
/* Warning */
/* -------------------
        Paths
-------------------- */
/* For source only. Modified in gulp for dist */
/* -------------------
       Em Sizes
-------------------- */
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/* -------------------
       Icons
-------------------- */
/* Maximum Glyph Width of Icon */
/* -------------------
     Neutral Text
-------------------- */
/* -------------------
     Brand Colors
-------------------- */
/* -------------------
      Borders
-------------------- */
/* -------------------
    Derived Values
-------------------- */
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/* -------------------
  Exact Pixel Values
-------------------- */
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/* -------------------
      Disabled
-------------------- */
/* -------------------
        Hover
-------------------- */
/* ---  Shadows  --- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Focus
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
    Down (:active)
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Active
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* ---  Tertiary  --- */
/* ---  Bright  --- */
/*******************************
 States shared in Form-related components
 *******************************/
/* Form state */
/* Input state */
/* AutoFill */
/* Dropdown state */
/* Focused state */
/* Placeholder state */
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/* General */
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Font Awesome Pro only */
/* Font Awesome Pro only */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Flyout */
/* Modal */
/* Nag */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/* -------------------
       Fonts
-------------------- */
/* -------------------
      Base Sizes
-------------------- */
/* This is the single variable that controls them all */
/* The size of page text */
/* -------------------
    Border Radius
-------------------- */
/* See Power-user section below
   for explanation of @px variables
*/
/* -------------------
    Brand Colors
-------------------- */
/* Whenever a color needs to get calculated (screen()/multiply()) out of a base color */
/* --------------
  Page Heading
--------------- */
/* --------------
   Form Input
--------------- */
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/* -------------------
    Focused Input
-------------------- */
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/* -------------------
        Sizes
-------------------- */
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/* -------------------
        Page
-------------------- */
/* -------------------
      Paragraph
-------------------- */
/* -------------------
       Links
-------------------- */
/* -------------------
    Scroll Bars
-------------------- */
/* Inverted */
/* IE needs hex values */
/* Scrolling */
/* -------------------
  Highlighted Text
-------------------- */
/* -------------------
       Loader
-------------------- */
/* -------------------
        Grid
-------------------- */
/* -------------------
     Transitions
-------------------- */
/* -------------------
     Breakpoints
-------------------- */
/* -------------------
      Site Colors
-------------------- */
/* ---  Colors  --- */
/* ---  Light Colors  --- */
/* ---   Neutrals  --- */
/* --- Colored Backgrounds --- */
/* --- Colored Headers --- */
/* --- Colored Text --- */
/* --- Light Colored Text --- */
/* --- Hovered Colored Text --- */
/* --- Colored Border --- */
/* --- Shadows --- */
/* Inverted */
/* Box Shadows */
/* -------------------
     Alpha Colors
-------------------- */
/* -------------------
       Accents
-------------------- */
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/* -------------------
    Emotive Colors
-------------------- */
/* Positive */
/* Negative */
/* Info */
/* Warning */
/* -------------------
        Paths
-------------------- */
/* For source only. Modified in gulp for dist */
/* -------------------
       Em Sizes
-------------------- */
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/* -------------------
       Icons
-------------------- */
/* Maximum Glyph Width of Icon */
/* -------------------
     Neutral Text
-------------------- */
/* -------------------
     Brand Colors
-------------------- */
/* -------------------
      Borders
-------------------- */
/* -------------------
    Derived Values
-------------------- */
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/* -------------------
  Exact Pixel Values
-------------------- */
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/* -------------------
      Disabled
-------------------- */
/* -------------------
        Hover
-------------------- */
/* ---  Shadows  --- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Focus
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
    Down (:active)
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Active
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* ---  Tertiary  --- */
/* ---  Bright  --- */
/*******************************
 States shared in Form-related components
 *******************************/
/* Form state */
/* Input state */
/* AutoFill */
/* Dropdown state */
/* Focused state */
/* Placeholder state */
/* Component's site.variables */
/* Site theme site.variables */
/** @see node_modules/fomantic-ui-less/themes/default/globals/site.variables **/
/* ---  Colors  --- */
/* ---  Light Colors  --- */
/* --------------
  Page Heading
--------------- */
/* -------------------
    Emotive Colors
-------------------- */
/* Positive */
/* Negative */
/* Info */
/* Warning */
/* -------------------
 Component Variables
--------------------- */
/* Default */
/*******************************
         Site Settings
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/* General */
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Font Awesome Pro only */
/* Font Awesome Pro only */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Flyout */
/* Modal */
/* Nag */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/* -------------------
       Fonts
-------------------- */
/* -------------------
      Base Sizes
-------------------- */
/* This is the single variable that controls them all */
/* The size of page text */
/* -------------------
    Border Radius
-------------------- */
/* See Power-user section below
   for explanation of @px variables
*/
/* -------------------
    Brand Colors
-------------------- */
/* Whenever a color needs to get calculated (screen()/multiply()) out of a base color */
/* --------------
  Page Heading
--------------- */
/* --------------
   Form Input
--------------- */
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/* -------------------
    Focused Input
-------------------- */
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/* -------------------
        Sizes
-------------------- */
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/* -------------------
        Page
-------------------- */
/* -------------------
      Paragraph
-------------------- */
/* -------------------
       Links
-------------------- */
/* -------------------
    Scroll Bars
-------------------- */
/* Inverted */
/* IE needs hex values */
/* Scrolling */
/* -------------------
  Highlighted Text
-------------------- */
/* -------------------
       Loader
-------------------- */
/* -------------------
        Grid
-------------------- */
/* -------------------
     Transitions
-------------------- */
/* -------------------
     Breakpoints
-------------------- */
/* -------------------
      Site Colors
-------------------- */
/* ---  Colors  --- */
/* ---  Light Colors  --- */
/* ---   Neutrals  --- */
/* --- Colored Backgrounds --- */
/* --- Colored Headers --- */
/* --- Colored Text --- */
/* --- Light Colored Text --- */
/* --- Hovered Colored Text --- */
/* --- Colored Border --- */
/* --- Shadows --- */
/* Inverted */
/* Box Shadows */
/* -------------------
     Alpha Colors
-------------------- */
/* -------------------
       Accents
-------------------- */
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/* -------------------
    Emotive Colors
-------------------- */
/* Positive */
/* Negative */
/* Info */
/* Warning */
/* -------------------
        Paths
-------------------- */
/* For source only. Modified in gulp for dist */
/* -------------------
       Em Sizes
-------------------- */
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/* -------------------
       Icons
-------------------- */
/* Maximum Glyph Width of Icon */
/* -------------------
     Neutral Text
-------------------- */
/* -------------------
     Brand Colors
-------------------- */
/* -------------------
      Borders
-------------------- */
/* -------------------
    Derived Values
-------------------- */
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/* -------------------
  Exact Pixel Values
-------------------- */
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/* -------------------
      Disabled
-------------------- */
/* -------------------
        Hover
-------------------- */
/* ---  Shadows  --- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Focus
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
    Down (:active)
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Active
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* ---  Tertiary  --- */
/* ---  Bright  --- */
/*******************************
 States shared in Form-related components
 *******************************/
/* Form state */
/* Input state */
/* AutoFill */
/* Dropdown state */
/* Focused state */
/* Placeholder state */
/* Packaged Theme */
/* Site Theme */
/** @see node_modules/fomantic-ui-less/themes/default/globals/site.variables **/
/* ---  Colors  --- */
/* ---  Light Colors  --- */
/* --------------
  Page Heading
--------------- */
/* -------------------
    Emotive Colors
-------------------- */
/* Positive */
/* Negative */
/* Info */
/* Warning */
/* -------------------------
    Central Color Map
------------------------- */
/* Default */
/***********************************************************
  Central Color Mapping Base for all components to iterate
***********************************************************/
/***********************************************************
  Color Mapping Base for form components to iterate
***********************************************************/
/* Packaged colors.less */
/***********************************************************
  Central Color Mapping Base for all components to iterate
***********************************************************/
/***********************************************************
  Color Mapping Base for form components to iterate
***********************************************************/
/* Packaged Theme */
/* Site Theme */
/*******************************
             Mix-ins
*******************************/
/* ------------------
       Fonts
------------------- */
/* ------------------
     Overrides
------------------- */
/* End Config */
.UserIndicator {
  display: flex;
  --size: 2.3em;
  --font-size: 1em;
}
.UserIndicator-icon {
  position: relative;
  --color: #888888;
  --image: none;
}
.UserIndicator-icon > span {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
.UserIndicator-icon > span::before {
  content: '';
  border-radius: 500rem;
  display: block;
  padding: 0.25em;
  width: var(--size);
  height: var(--size);
  text-align: center;
  line-height: 1em;
  background-color: var(--color);
}
.UserIndicator-icon > span > span {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  border-radius: 500rem;
  overflow: hidden;
}
.UserIndicator-icon > span > span::before {
  content: attr(data-initials);
  color: #fff;
  font-size: var(--font-size);
  display: inline-block;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.UserIndicator-icon > span > span::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  border-radius: 500rem;
  overflow: hidden;
  content: '';
  background-image: var(--image);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 0.01em solid transparent;
}
.UserIndicator-icon > span::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  border-radius: 500rem;
  overflow: hidden;
  content: '';
  border: 0.02em solid #888888;
}
.UserIndicator-name {
  align-self: center;
  margin-left: 0.5em;
}
.OpenAccessIcon {
  overflow: hidden;
  position: relative;
  margin: 0 !important;
}
.OpenAccessIcon::after {
  content: attr(data-partner);
  display: block;
  position: absolute;
  left: 0.5em;
  font-size: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  font-family: monospace;
  color: #fff;
  font-weight: bold;
  width: calc(100% - 1em);
  overflow: hidden;
}
.OpenAccessIcon::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 0.05em solid #fff;
  border-radius: 0.25em;
  background-color: #080878 !important;
}
.OpenAccessIcon[data-partner="1und1"] {
  overflow: hidden;
  margin: 0 !important;
  position: relative;
}
.OpenAccessIcon[data-partner="1und1"]::after {
  content: attr(data-partner);
  display: block;
  position: absolute;
  left: 0.5em;
  font-size: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  font-family: monospace;
  color: #fff;
  font-weight: bold;
  width: calc(100% - 1em);
  overflow: hidden;
}
.OpenAccessIcon[data-partner="1und1"]::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 0.05em solid #fff;
  border-radius: 0.25em;
  background-color: #080878 !important;
}
.OpenAccessIcon[data-partner="1und1"]::after {
  display: none;
}
.OpenAccessIcon[data-partner="1und1"]::before {
  content: '';
  border: none;
  border-radius: 0;
  background-color: transparent !important;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.OpenAccessIcon[data-partner="1und1"]::before {
  background-image: url("../images/openaccess/1und1/medium.svg");
}
.OpenAccessIcon[data-partner="1und1"].big {
  font-size: 2.4em !important;
}
.OpenAccessIcon[data-partner="1und1"].mini::before,
.OpenAccessIcon[data-partner="1und1"].tiny::before {
  background-image: url("../images/openaccess/1und1/tiny.svg");
}
.OpenAccessIcon[data-partner="1und1"].small::before {
  background-image: url("../images/openaccess/1und1/small.svg");
}
.OpenAccessIcon[data-partner="com-in"] {
  overflow: hidden;
  margin: 0 !important;
  position: relative;
}
.OpenAccessIcon[data-partner="com-in"]::after {
  content: attr(data-partner);
  display: block;
  position: absolute;
  left: 0.5em;
  font-size: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  font-family: monospace;
  color: #fff;
  font-weight: bold;
  width: calc(100% - 1em);
  overflow: hidden;
}
.OpenAccessIcon[data-partner="com-in"]::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 0.05em solid #fff;
  border-radius: 0.25em;
  background-color: #080878 !important;
}
.OpenAccessIcon[data-partner="com-in"]::after {
  display: none;
}
.OpenAccessIcon[data-partner="com-in"]::before {
  content: '';
  border: none;
  border-radius: 0;
  background-color: transparent !important;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.OpenAccessIcon[data-partner="com-in"]::before {
  background-image: url("../images/openaccess/com-in/medium.svg");
}
.OpenAccessIcon[data-partner="com-in"].big {
  font-size: 2.4em !important;
}
.OpenAccessIcon[data-partner="com-in"].mini::before,
.OpenAccessIcon[data-partner="com-in"].tiny::before {
  background-image: url("../images/openaccess/com-in/tiny.svg");
}
.OpenAccessIcon[data-partner="com-in"].small::before {
  background-image: url("../images/openaccess/com-in/small.svg");
}
.OpenAccessIcon[data-partner="telekom"] {
  overflow: hidden;
  margin: 0 !important;
  position: relative;
}
.OpenAccessIcon[data-partner="telekom"]::after {
  content: attr(data-partner);
  display: block;
  position: absolute;
  left: 0.5em;
  font-size: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  font-family: monospace;
  color: #fff;
  font-weight: bold;
  width: calc(100% - 1em);
  overflow: hidden;
}
.OpenAccessIcon[data-partner="telekom"]::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 0.05em solid #fff;
  border-radius: 0.25em;
  background-color: #080878 !important;
}
.OpenAccessIcon[data-partner="telekom"]::after {
  display: none;
}
.OpenAccessIcon[data-partner="telekom"]::before {
  content: '';
  border: none;
  border-radius: 0;
  background-color: transparent !important;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.OpenAccessIcon[data-partner="telekom"]::before {
  background-image: url("../images/openaccess/telekom/medium.svg");
}
.OpenAccessIcon[data-partner="telekom"].big {
  font-size: 2.4em !important;
}
.OpenAccessIcon[data-partner="telekom"].mini::before,
.OpenAccessIcon[data-partner="telekom"].tiny::before {
  background-image: url("../images/openaccess/telekom/tiny.svg");
}
.OpenAccessIcon[data-partner="telekom"].small::before {
  background-image: url("../images/openaccess/telekom/small.svg");
}
.OpenAccessIcon[data-partner="vitroconnect"] {
  overflow: hidden;
  margin: 0 !important;
  position: relative;
}
.OpenAccessIcon[data-partner="vitroconnect"]::after {
  content: attr(data-partner);
  display: block;
  position: absolute;
  left: 0.5em;
  font-size: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  font-family: monospace;
  color: #fff;
  font-weight: bold;
  width: calc(100% - 1em);
  overflow: hidden;
}
.OpenAccessIcon[data-partner="vitroconnect"]::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 0.05em solid #fff;
  border-radius: 0.25em;
  background-color: #080878 !important;
}
.OpenAccessIcon[data-partner="vitroconnect"]::after {
  display: none;
}
.OpenAccessIcon[data-partner="vitroconnect"]::before {
  content: '';
  border: none;
  border-radius: 0;
  background-color: transparent !important;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.OpenAccessIcon[data-partner="vitroconnect"]::before {
  background-image: url("../images/openaccess/vitroconnect/medium.svg");
}
.OpenAccessIcon[data-partner="vitroconnect"].big {
  font-size: 2.4em !important;
}
.OpenAccessIcon[data-partner="vitroconnect"].mini::before,
.OpenAccessIcon[data-partner="vitroconnect"].tiny::before {
  background-image: url("../images/openaccess/vitroconnect/tiny.svg");
}
.OpenAccessIcon[data-partner="vitroconnect"].small::before {
  background-image: url("../images/openaccess/vitroconnect/small.svg");
}
.DocumentTag {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
.DocumentTag-label {
  margin: 0!important;
}
.AccordionTable {
  position: relative;
}
.ui.grid .AccordionTable-menu-left.column,
.ui.grid .AccordionTable-menu-right.column {
  display: flex;
}
.ui.grid .AccordionTable-menu-right.column {
  flex: 2;
}
.ui.grid .AccordionTable-menu-left.column {
  flex: 1;
  min-width: fit-content;
  width: fit-content;
  justify-content: left;
}
.AccordionTable .header-button {
  position: absolute;
  right: -4px;
}
.AccordionTable .header-button .button .icon {
  opacity: 1!important;
}
.AccordionTable .header-button .tertiary.button:hover,
.AccordionTable .header-button .tertiary.button:active,
.AccordionTable .header-button .tertiary.button:focus,
.AccordionTable .header-button .button,
.AccordionTable .header-button .button:hover,
.AccordionTable .header-button .button:active,
.AccordionTable .header-button .button:focus {
  box-shadow: none;
  color: white;
}
@media (width <= 768px) {
  .AccordionTable .ui.grid {
    flex-direction: column;
  }
  .ui.grid .AccordionTable-menu-left.column {
    width: 100%;
    justify-content: left;
    flex: none;
  }
  .ui.grid .AccordionTable-menu-right.column {
    width: 100%;
    justify-content: center;
  }
}
a.pointer,
.pointer {
  white-space: nowrap;
  border-radius: 0;
  background: #888;
  padding: 2px 7px;
  line-height: 20px;
  font-size: 90%;
  font-weight: normal;
  font-family: monospace;
  color: white;
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: transparent;
}
a.pointer b,
.pointer b {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, "Lucida Sans Unicode", sans-serif;
}
a.pointer i.circular.icon,
.pointer i.circular.icon {
  font-size: 0.7em;
  line-height: 0.7em;
}
a.pointer.delete {
  background: none;
  color: #080878;
}
a.pointer.delete:hover {
  color: #050548;
  background: #eee;
}
a.pointer:hover {
  background: #080878;
}
.EntityLink-info {
  margin-left: 7px;
}
.ImageCropper-preview {
  border-radius: 100em;
  overflow: hidden;
  border: 2px solid #888888;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
}
.ImageCropper-preview .cr-viewport {
  border-color: transparent;
}
.EntityCard {
  color: #888888;
}
.EntityCard:hover,
.EntityCard:focus,
.EntityCard:active {
  color: #888888;
}
.EntityCard .content > .header > .EntityLink .EntityLink-info {
  display: block;
  margin-top: 0.3em;
}
.DocumentCard-image:not(.DocumentCard-image--placeholder) {
  position: relative !important;
  padding: 1em !important;
}
.DocumentCard-image:not(.DocumentCard-image--placeholder)::after {
  content: '';
  display: block;
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: var(--thumbnail);
}
.DocumentCard-image--placeholder .ui.placeholder .image:not(.header, .ui, .icon) {
  height: 13.5em !important;
  padding-top: 13.5em !important;
}
.DocumentCard-header {
  word-break: break-all;
}
.DocumentCard-preview {
  width: 100%;
  max-height: calc(100vh - 200px);
  max-width: 100%;
}
.DocumentCard-preview--full {
  height: calc(100vh - 200px);
}
.DocumentCard-preview img {
  margin: auto;
  height: auto !important;
  width: auto !important;
  max-width: inherit !important;
  max-height: inherit !important;
}
.DocumentCard-preview .ui.embed {
  padding: 0 !important;
  height: calc(100vh - 200px) !important;
}
.c-task-history {
  display: block;
  margin-top: 1rem;
}
.c-task-history #topicTreeSelect {
  align-items: center;
  display: flex;
}
.AvatarEditor-overlay {
  position: relative;
  display: inline-block;
  font-size: 3em;
  border-radius: 100em;
  overflow: hidden;
  cursor: pointer;
}
.AvatarEditor-overlay .dimmer span {
  font-size: 0.3em !important;
  margin-bottom: 0 !important;
}
.AvatarEditor-image {
  max-width: 600px;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.AvatarEditor-image .UserIndicator {
  font-size: 130px;
  display: inline;
}
.BuildingSearchResults-building-link {
  margin: 3px !important;
}
.ShowBuilding-footer {
  display: flex;
  justify-content: right;
}
.DocumentList {
  font-size: 0.7em;
}
.DocumentList .column > .DocumentCard {
  height: 100%;
}
.c-service-table .ui.table {
  margin-top: 0;
}
.c-service-table .td-multi-lines span {
  display: block;
}
.ModalMessage-actions {
  display: flex;
  justify-content: center;
}
.ModalMessage-qr-code {
  width: fit-content;
  display: block;
  margin: auto;
}
.ModalMessage-qr-code > * {
  display: block;
  height: 10em;
  width: 10em;
  max-height: 25vh;
  max-width: 25vh;
}
.QRSnapshoter {
  --scan-display: none;
  --result-display: none;
  --aspect-padding-top: 56.25%;
  --vertical-space: 28rem;
  --max-width: 100%;
  margin: auto;
  width: 100% !important;
}
.QRSnapshoter--scanning {
  --scan-display: block;
}
.QRSnapshoter--result {
  --result-display: block;
}
.QRSnapshoter--result .QRSnapshoter-video-container {
  padding-bottom: 0;
}
.QRSnapshoter > .ui.card {
  width: 100%;
}
.QRSnapshoter-redo {
  margin-bottom: 0.5rem !important;
}
.QRSnapshoter-video {
  overflow: hidden;
}
.QRSnapshoter-video .ui.placeholder {
  max-width: none;
}
.QRSnapshoter-video .ui.placeholder > .image {
  width: 100%;
  height: 0 !important;
  padding-top: var(--aspect-padding-top);
}
.QRSnapshoter-video-scale {
  margin: auto;
  margin-top: 0.5rem;
  max-width: var(--max-width);
}
.QRSnapshoter-video-container {
  position: relative;
  padding-bottom: var(--aspect-padding-top);
}
.QRSnapshoter-video-container .ui.progress {
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.QRSnapshoter-video-container .ui.progress .bar .progress {
  color: #fff !important;
}
.QRSnapshoter-video-container .ui.progress,
.QRSnapshoter-video-container .ui.progress .bar {
  border-radius: 0;
}
.QRSnapshoter-video-result {
  display: var(--result-display);
}
.QRSnapshoter-video-scanner {
  display: var(--scan-display);
  position: absolute;
  top: 0;
  width: 100%;
}
.QRSnapshoter-video-scanner,
.QRSnapshoter-video-result {
  inset: 0;
  max-width: 100%;
}
.QRSnapshoter-video-scanner > video,
.QRSnapshoter-video-result > video,
.QRSnapshoter-video-scanner > img,
.QRSnapshoter-video-result > img {
  position: relative !important;
  width: 100% !important;
  opacity: 1 !important;
  height: 100% !important;
}
ul.ui.list li::before {
  content: unset;
}
.ui.list > .item > i.icon + .content {
  text-align: left;
}
.Map {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #eef0d5;
}
.Map .map {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  position: relative;
}
.Map .map-button-container {
  margin-bottom: 5px;
  position: absolute;
  z-index: 1;
}
.BuildingSelection-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.UnitAssignmentApp .sortable-chosen:not(.sortable-fallback) {
  border: 2px dashed #080878;
}
.LabelPrinter-qr-code-preview {
  max-width: 100% !important;
}
.LabelPrinter-qr-code-preview img {
  max-width: 100% !important;
}
.ModalWizard-header-main {
  color: white;
}
.ModalWizard-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ModalWizard-actions-left {
  text-align: left;
}
.ModalWizard-actions-left button {
  margin-left: 0 !important;
}
.ModalWizard-actions-middle {
  text-align: center;
}
.ModalWizard-actions-right {
  text-align: right;
}
.ModalWizard-actions-right button {
  margin-right: 0 !important;
}
.ModalWizard-actions .ui.grid {
  width: 100%;
  margin: -1rem 0;
}
.ModalWizard-content {
  text-align: center;
  position: relative;
}
.ModalWizard-content--loading::after {
  content: '';
  display: block;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  position: absolute;
  inset: 0;
}
@media only screen and (max-width: 991.98px) {
  .ModalWizard-content > .ui.steps .step {
    font-size: 0.6rem;
  }
}
@media only screen and (width <= 767.98px) {
  .ui.modal > .actions {
    padding: 1rem !important;
  }
  .ui.mini.steps .step .icon {
    margin: 0;
  }
  .ui.mini.steps .step .content {
    display: none !important;
  }
  .ModalWizard-header-main {
    display: block;
  }
  .ModalWizard-header-separator {
    display: none;
  }
  .ui.steps {
    display: flex;
  }
}
.ShowPerson-buttons {
  margin-bottom: 10px;
}
.EntityCardList-header {
  font-weight: normal;
  display: flex;
  flex-direction: column;
  color: grey;
}
.EntityCardList-info {
  margin-top: 0.3em;
}
.EntityCardList-reference,
.EntityCardList-info {
  margin-left: 0.3em;
}
.EntityCardList span.ui.text {
  line-height: 1.3;
}
.ShortcutList-shortcut {
  background: #888;
  color: white;
  display: inline-block;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 0 4px 4px 0;
}
.ShortcutList-shortcut a {
  color: inherit;
  cursor: pointer;
}
.ShortcutList-shortcut span {
  padding-left: 10px;
  padding-right: 10px;
  color: #C0C0C0;
}
.ShortcutList-shortcut-icon {
  cursor: pointer;
}
.ShortcutEditDialog-label-with-hint {
  display: block;
}
.LogoImage {
  display: block;
}
.LogoImage a {
  display: inline-block;
}
.LogoImage img {
  width: 100%;
  max-width: 188px;
}
.PopupHeader {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 8fr 4fr;
  grid-template-areas: "logo header action";
  gap: 0;
}
.PopupHeader-header {
  grid-area: header;
  text-align: center;
}
.PopupHeader-header > .ui.header {
  font-size: 1.5rem;
}
.PopupHeader-title {
  color: rgba(0, 0, 0, 0.6);
}
.PopupHeader-link {
  margin-left: 0.5rem;
  font-size: 1rem;
}
.PopupHeader-logo {
  grid-area: logo;
}
.PopupHeader-action {
  grid-area: action;
  text-align: right;
}
@media screen and (max-width: 767.98px) {
  .PopupHeader-action {
    visibility: hidden;
  }
}
.PopupHeader-action > *:first-child,
.PopupHeader-header > *:first-child {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 767.98px) {
  main.responsive .PopupHeader {
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    grid-template-areas: "logo action" "header header";
  }
  main.responsive .PopupHeader-logo > .LogoImage img {
    max-height: 50px;
  }
}
