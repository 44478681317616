@element: "site";
@type: "global";

@import url("fomantic-ui/theme.config");
@import url("var");

each(@colors, {
  @keyValue: replace(replace(replace(@key, "@", ""), "primary", "primaryColor"), "secondary", "secondaryColor");
  @keyName: replace(@key, "@", "");
  .c-background--@{keyName} {
    @colorValue: @@keyValue;
    background-color: @colorValue;
  }
})

.icon.circular.border {
  border: 1px solid #fff;
}

.table-caption {
  text-align: center;
}

.table-total {
  text-align: right;
}

.c-icon-menu--logo {
  margin-top: 20px;
  display: block;

  img {
    width: 100%;
  }
}

nav.navigation,
.navigation {
  display: inline-flex;
  flex-flow: row nowrap;
  width: auto;
  margin: 0 0 2rem;

  a {
    display: inline-block;
    margin: 0;
  }
}

.box {
  border: 0;
  border-collapse: collapse;
  box-shadow: 0 0 10px #bbb;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  margin: 0 0 10px;
  padding: 0;
}

.box-content {
  padding: 15px;
}

.box-header {
  background: @mainColor;
  font-weight: 500;
  color: white;
  line-height: 1.5;
  margin: 0 0 10px;
  padding: 10px 15px;
}

.box-section {
  border-style: solid;
  border-color: @borderColor;
  border-width: 0 0 1px;
  padding: 0;
}

.row {
  display: flex;
  align-items: center;
  min-height: 3.314em;
}

.row.controls {
  justify-content: flex-end;
  margin: 2rem 0;

  .field-column {
    flex: 0 0 calc(100% - @labelColumnWidth);
    max-width: calc(100% - @labelColumnWidth);
  }

  .aButton {
    margin-right: 0.7rem;
  }
}

.row.sum {
  justify-content: flex-end;

  .label {
    text-align: right;
  }

  .field-column {
    flex: 0 1 calc(50% - 25px);
  }
}

.row.textarea {
  align-items: baseline;
}

.field-column {
  display: flex;
  padding: 0 15px 0 0;
  flex: 1 1;

  .form-field {
    position: relative;

    input {
      padding-right: 2.8em;
    }
  }

  input:active + .measurement-unit,
  input:focus + .measurement-unit {
    color: white;
  }

  .measurement-unit {
    position: absolute;
    top: 0;
    right: 0.8em;
    margin-top: 0.5rem;
  }

  .form-field,
  & > textarea,
  & > select,
  & > input {
    margin: 0.6rem 0.4rem;
    flex: 1 1 0px;
  }
}

.percental-price > .form-field:last-child {
  flex: 2 0;
}

.percental-price > .form-field:first-child input {
  text-align: right;
}

.deadline {
  select {
    width: 25%;
  }

  .row {
    width: 100%;

    input:last-child {
      max-width: 33.3333%;
    }
  }
}

.cell.label {
  width: @labelColumnWidth;
  font-size: 100%;
  color: black;
  padding: 0 15px;
}

.outside-controls {
  text-align: center;
}

select.bandwidth optgroup {
  display: none;
}

.history-comment {
  border-left: 1px solid #aaa;
  padding: 5px 10px;
  margin: 5px 0;

  &:nth-child(2n) {
    background-color: #f0f0f0;
  }

  h5 {
    font-weight: 900;
    color: black;
    font-size: 1rem;
    margin: 0 0 1em;
  }
}

.row-project > div {
  margin-left: 0.6em;
  margin-right: 0.7em;
  text-align: center;
}

.grid-margin-x > .cell {
  margin-left: @cellSpacing;
  margin-right: @cellSpacing;
}

.grid-margin-y > .cell {
  margin-top: @cellSpacing;
  margin-bottom: @cellSpacing;
}

ul.field-errors {
  color: @errorColor;
  font-size: 0.9rem;
  margin: 0 0 0.5rem @labelColumnWidth;
  padding-left: 0;
}

.aTable ul.field-errors,
.aTableCell ul.field-errors {
  margin-top: 0.25rem;
  margin-left: 0;
}

.timeline {
  position: relative;
  padding: 0.5rem 0;
  font-size: 0.95rem;
}

.time-entry {
  position: relative;
  background-color: #eeefee;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 1rem 0;
}

.ready4service.enough {
  background-color: green;
}

.ready4service.just-enough {
  background-color: darkorange;
}

.ready4service.not-enough {
  background-color: @dangerColor;
}

.time-entry.ready4service {
  width: 440px;
  color: white;

  .timeline-content {
    div:first-child {
      width: 30%;
      padding: 8px 0;
    }

    div {
      width: 30%;
    }

    div:last-child {
      width: 40%;
    }

    .button {
      margin: 1px 0;
    }
  }
}

.time-entry h6 {
  font-size: 100%;
  font-weight: 800;
  color: black;
  margin: 0.1rem 0 0.3rem;
}

.time-entry p {
  margin: 0 0 0.3rem;
}

.time-entry-left {
  width: 230px;
  text-align: right;
  padding-right: 64px;
  padding-left: 1rem;
}

.time-entry-right {
  width: 540px;
  margin-left: 184px;
  padding-left: 64px;
  padding-right: 1rem;
}

.timeline-checkbox {
  position: absolute;
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  background-color: white;
  border: 5px solid @mainColor;
  z-index: 20;
}

.time-entry-left .timeline-checkbox {
  right: 0.5rem;
}

.time-entry-right .timeline-checkbox {
  left: 0.5rem;
}

.time-checkmark {
  display: block;
  height: 20px;
  width: 10px;
  margin: 0 auto;
  border-color: @mainColor;
  border-style: solid;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

.time-checkmark.in-work {
  border-color: @secondaryColor;
}

.timeline-content {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 30%;
    padding: 0 0.5rem 0 0;
  }

  div:nth-child(2n) {
    width: 20%;
  }
}

.timeline-vertical {
  position: absolute;
  top: 0;
  left: 205px;
  width: 5px;
  height: 100%;
  z-index: 10;
  background-color: @mainColor;
}

.contents-table {
  width: 800px;
}

.legend {
  padding: 0.9rem 0 0.7rem 0.3rem;

  li {
    display: inline-block;
    white-space: nowrap;
    border: none;
    padding: 0.3rem 0.7rem 0 0;
  }

  span {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 0.1rem;
    border-radius: 50%;
  }
}

.callout {
  margin-bottom: 0.5rem;
  padding: 1.5rem;
  color: white;

  &.success {
    background-color: @successColor;
  }

  &.error {
    background-color: @errorColor;
  }
}

.datepicker {
  z-index: 10000 !important;
}

.group-span {
  font-size: 75%;
  cursor: pointer;
}

.apl-year {
  border: 0;
  margin: 25px;
}

:not(.ui) > .select-building.select-building.select-building {
  display: inline-block;
  min-width: 200px;
}

.task_search_wrapper {
  padding: 0 4px;
}

.task_search_field {
  height: 35px;
}

.newsText {
  white-space: pre-wrap;
}

.projects-toggle-button {
  margin: 0;
  text-align: center;
  font-size: 70%;
  width: 100%;
  height: 2em;
  line-height: 2em;
  background: white;
}

.projects-toggle-row {
  border-top: 1px solid #ddd;
  padding-top: 0;
  max-height: 200px;
}

.aTable.separatedRows .person-address-book {
  padding-top: 20px;
  padding-bottom: 20px;
}

.lineid {
  font-family: monospace;
}

.dslamport {
  border-radius: 10px;
  padding: 1px 7px;
  font-size: 90%;
  color: white;
  display: inline-block;
  background: #2e6da4;
}

.service-diagnose {
  position: relative;

  span.time {
    position: absolute;
    top: 25px;
    right: 25px;
    color: #444;
  }

  dl {
    display: grid;
    grid-template-columns: max-content auto;
  }

  dt {
    grid-column-start: 1;
  }

  dd {
    grid-column-start: 2;

    ul {
      padding: 0;
    }
  }

  .progress {
    display: inline-block;
    position: relative;

    --color: blue;
    --background: gray;

    progress {
      height: 20px;
      appearance: none;
      background: var(--background);
      border-left: 5px solid var(--color);

      &::-webkit-progress-bar {
        background: var(--background);
      }

      &::-webkit-progress-value {
        background: var(--color);
      }

      &::-moz-progress-bar {
        background: var(--color);
      }
    }

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -2px;
      transform: translate(-50%, -50%);
    }
  }

  .service-diagnose--container {
    display: flex;

    > div {
      flex-grow: 1;
    }
  }

  .service-diagnose--macs {
    table {
      width: 100%;
    }
  }
}

.person-form-ont {
  position: relative;

  .ont-input {
    width: 85%;
    display: inline-block;
  }

  .edit-unit {
    display: inline-block;
    margin-left: 5px;
  }
}

.person-building-details {
  overflow-wrap: break-word;
}

#gebaeudeList .aTable-full .aTableCell {
  padding-top: 0;
  padding-bottom: 0;
}

.c-main-menu {
  &-grid-container {
    @media only screen and (max-width: @computerBreakpoint) {
      width: auto !important;
      margin-left: 1em !important;
      margin-right: 1em !important;
    }
  }

  &-grid {
    width: max-content;
    justify-content: left;

    @media only screen and (min-width: @largeMonitorBreakpoint) {
      width: auto;
      justify-content: center;
    }

    > .column {
      display: flex !important;
      align-items: end;
    }
  }

  &-container {
    min-width: 747px;
  }
}

.deteconsync-log-reference {
  display: block;
  margin-top: 5px;
}

button.task-card {
  border-width: 0 0 0 10px;
  border-style: solid;
  padding: 7px;
  margin-top: 0; //overwrite from aCard class
  margin-bottom: 20px; //instead of margin-top from aCard we need margin-bottom
  font-size: 85%;
  text-align: left;
}

.unit-details-verification-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin-top-10px {
  margin-top: 10px !important;
}

.margin-bottom-5px {
  margin-bottom: 5px !important;
}

.margin-bottom-10px {
  margin-bottom: 10px !important;
}
