.EntityCard {
  color: @grey;

  &:hover, &:focus, &:active {
    color: @grey;
  }

  .content > .header > .EntityLink {
    .EntityLink-info {
      display: block;
      margin-top: 0.3em;
    }
  }
}
