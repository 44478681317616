@import url("var.less");
@import url("fonts.less");

/* Content Structure --- */

.box {
  border: 0;
  border-collapse: collapse;
  overflow-x: hidden;
  box-shadow: 0 0 10px #bbb;
  border-radius: 4px;
  box-sizing: border-box;
}

.aTable {
  &:not(.ui) {
    display: table;
    background: white;
    border-radius: 4px;

    &.transparent {
      background: transparent;
    }

    &.TD_LISTE {
      border-radius: 0 0 4px 4px;
      border-collapse: collapse;

      caption {
        border-radius: 4px 4px 0 0;
      }
    }

    &.padded > .aTableCell {
      padding: 15px;
    }

    &.separatedRows {
      tr,
      .aTableRow > .aTableCell {
        border-bottom: 1px solid #ddd;

        > * {
          padding: 5px 20px;
        }
      }
    }

    tr,
    .aTableRow {
      display: table-row;

      &.hover:hover {
        background: #ddd;
      }
    }

    td,
    th,
    .aTableCell {
      display: table-cell;
      padding: 5px 15px;
      vertical-align: middle;
      box-sizing: border-box;

      &.verticalTop {
        vertical-align: top;
      }

      &.verticalBottom {
        vertical-align: bottom;
      }

      &.aInnerTable {
        padding: 15px;
      }

      &.stretching {
        width: 100%;
      }

      &.nowrap {
        white-space: nowrap;
      }

      &.label {
        min-width: 150px;
        width: 150px;
      }

      &.layout-hidden {
        display: none;
      }
    }

    &.second .thead {
      background: #666;
    }

    thead,
    .thead {
      background: @mainColor;
      color: white;
      font-weight: 500;
      padding: 10px 15px;

      *:not(.ui, .ui *) {
        color: white;
      }

      &.category {
        border-left: 1px solid lighten(@mainColor, 20%);
      }
    }

    .tfoot {
      background: #888;
      color: white;
      font-weight: 500;
      padding: 10px 15px;

      * {
        color: white;
      }

      > .aTableCell {
        border: 0;
      }
    }

    &.aList {
      &.variantA {
        &,
        tr,
        .aTableRow {
          td,
          th,
          .aTableCell {
            padding: 3px 8px;
          }
        }
      }

      tr,
      .aTableRow {
        td,
        th,
        .aTableCell {
          padding: 7px 15px;
          line-height: 1.5;
        }

        &:hover {
          background: #ddd;
        }

        &.detailsOverview.selected {
          background: #f4f4f4;
        }

        &.detailsOverview td:first-of-type {
          border-left: 0 solid transparent;
        }

        &.details {
          background: #f4f4f4;

          td {
            box-shadow: inset 0 0 0 10px white;
            padding: 20px;
          }
        }

        &.aListGroup td {
          padding-bottom: 30px;
        }
      }
    }
  }
}

.bold { font-weight: 500; }

.aCard {
  background: white;
  box-shadow: 0 0 8px #bbb;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: normal;

  .more {
    h2:first-child {
      margin-top: 0;
    }

    .buttons > .button {
      margin: 0 !important;
    }

    .layout-third {
      margin: -2px;
      margin-top: -4px;

      .aCard {
        width: calc(33% - 2px);
        margin: 2px;
      }
    }
  }

  .cardControl > .ui.button.tertiary {
    padding: 0 !important;
    margin: 0 !important;
  }

  &.hover:hover {
    background-color: #eee;
    cursor: pointer;
    color: @mainColor;

    input,
    select {
      color: @mainColor;

      &[disabled] {
        color: #000;
      }
    }

    .section-comments {
      .section-comments-overlay {
        .section-comments-overlay-gradient {
          .backgroundLinearGradient(fade(#eee, 0), #eee);
        }

        .section-comments-overlay-more {
          background: #eee;
        }
      }
    }
  }

  &.fullsize {
    width: 100%;
  }
}

.more {
  display: none;

  &.visible {
    display: block;
  }
}

.d-none {
  display: none !important;
}

.js-fetch-content--loading .js-fetch-content--container {
  &::after {
    content: 'Laden...';
  }
}

&.noshadow {
  box-shadow: none;
}

&.noradius {
  border-radius: 0;
}

.inlineTableWrapper {
  display: block;
  width: 100%;
  box-sizing: border-box;

  .inlineTable {
    display: table;
    width: 100%;
    box-sizing: border-box;

    .inlineTableCell {
      display: table-cell;
      padding-right: 10px;

      &:last-of-type {
        padding-right: 0;
      }

      &.variantA {
        width: 125px;
      }

      &.layout-hidden {
        display: none;
      }
    }
  }
}

.inputFormWrapper {
  white-space: nowrap;

  &.calendar {
    .inputForm,
    .inputFormSubmit {
      font-size: 12px;
    }

    .inputForm {
      width: auto;
    }
  }
}

:not(.ui) > input,
:not(.ui) > textarea,
:not(.ui) > select {
  background-color: #eee;
  border: 1px solid #bbb;
  border-radius: 0;
  padding: 6px 10px;
  min-width: 100px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;

  &.inputForm,
  &.inputFormSubmit {
    display: inline-block;
    margin: 0;
    position: relative;
    width: auto;

    &:hover,
    &:focus,
    &:active {
      z-index: 100;
    }
  }

  &.inputFormSubmit {
    margin-left: -1px;
  }

  &.light {
    background-color: white;
    box-shadow: 0 0 5px #bbb;
    border: 0;

    &:hover {
      background-color: fade(@mainColor, 100%);
      color: white;
      border-color: @mainColor;
    }
  }

  &.number {
    text-align: right;
  }

  &:hover {
    background-color: fade(@mainColor, 50%);
    color: black;
    border-color: @mainColor;

    &[readonly] {
      background: white;
      color: black;
      border-color: #ddd;
    }
  }

  &:focus {
    background-color: fade(@mainColor, 100%);
    color: white;
    border-color: @mainColor;
    box-shadow: 0 0 8px @mainColor;
    outline: 0;

    &[readonly] {
      background: white;
      color: black;
      border-color: #ddd;
      box-shadow: none;
    }
  }

  &[type="submit"] {
    cursor: pointer;
  }

  &[type="radio"],
  &[type="checkbox"] {
    width: 20px;
    min-width: 0;

    + label {
      padding-left: 5px;
    }
  }

  &[readonly],
  &[disabled] {
    &,
    &.light,
    &:hover,
    &.light:hover {
      background: white !important;
      border-color: #ddd;
      cursor: not-allowed !important;
      color: #222;
    }
  }
}

select:not([multiple], [disabled]) {
  background-image: url("@{imagePath}/arrow_down.png");
  background-repeat: no-repeat;
  background-position: center right 6px;
  background-size: auto 25px;
  appearance: none;
  padding: 6px 35px 6px 10px;
  font-size: 100%;
  display: block;
  outline: 0;
  box-sizing: border-box;

  &:hover,
  &:focus {
    background-color: fade(@mainColor, 100%);
    background-image: url("@{imagePath}/arrow_down_inv.png");
    background-position: center right 6px;
    background-repeat: no-repeat;
    background-size: auto 25px;
    color: white;
    cursor: pointer;
  }

  &:focus {
    background-color: @mainColor;
    box-shadow: 0 0 8px @mainColor;
  }
}

input.mini,
select:not([multiple]).mini {
  padding: 1px 3px;
  background-color: transparent;
  border-width: 0 0 1px;
  border-color: transparent;
  box-shadow: none;
  color: #000;
  font-size: 100%;

  &:hover {
    background-color: #ccc;
  }

  &[disabled] {
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: #000;
    }
  }
}

select:not([multiple]).mini {
  background-image: none;

  &:hover {
    background-color: #ccc;
    background-image: url("@{imagePath}/arrow_down.png");
    background-position: center right 6px;
    background-repeat: no-repeat;
  }
}

label, .label {
  font-size: 90%;
  color: #888;
  padding-left: 0;
}

textarea {
  resize: vertical;
  min-height: 85px;
}

.fullSize {
  width: 100%;
  box-sizing: border-box;
}

main {
  margin: 0 auto;
  position: relative;
  padding: 20px;
  display: block;
  min-width: 1040px;
  width: 1040px;
  width: min-content;

  &.clip-left {
    margin: 0;
  }

  &.popup {
    max-width: 1000px;
    width: auto;
    min-width: auto;

    &:not(.responsive) {
      min-width: 1000px;
    }
  }

  &.popup-full {
    width: 100%;
    max-width: none;
  }

  &.mini {
    max-width: 868px;
    width: auto;
    min-width: auto;
    padding: 0 20px;
  }

  > .free {
    padding: 0 16px;
  }
}

.noWrap {
  white-space: nowrap;
}

.layout {
  width: 100%;
  max-width: 100%;
  position: relative;

  > div {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    width: 100%;

    &.layout-hidden {
      display: none;
    }
  }

  &-half > div {
    width: 49%;
    padding: 0 10px;

    &.padded:first-of-type {
      padding-right: 45px;
    }
  }

  &-third > div {
    width: 33%;
    &.double { width: 66%; }
  }

  &-fourth-small > div {
    width: 24%;
  }

  &-fourth > div {
    width: 25%;
    &.double { width: 50%; }
  }

  &-fifth > div {
    width: 20%;
    &.double { width: 40%; }
  }

  &.verticalTop {
    > div {
      vertical-align: top;
    }
  }

  &.verticalBottom {
    > div {
      vertical-align: bottom;
    }
  }
}

.menu-item .aCard {
  h2 {
    margin: 17px 0;
    font-size: 20px;
  }

  &:hover {
    background-color: @mainColor;

    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a {
      color: white;
    }
  }
}

/* Inteface Controls --- */

main a.aButton,
main .aButton,
main input[type="submit"],
main :not(.ui) > a.aButton,
main :not(.ui) > .aButton,
main :not(.ui) > input[type="submit"],
main button:not(.ui, .tox-tbtn, .aCard) {
  border-radius: 4px;

  &,
  .aCard.hover:hover & {
    border: 0 solid transparent;
    display: inline-block;
    color: white;
    padding: 6px 15px;
    text-decoration: none;
    cursor: pointer;
    font-size: 100%;
    text-align: center;
    background: @mainColor;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    appearance: none;
    box-shadow: 0 1px 2px #444;
    position: relative;

    &.inverted {
      background: #fff;
      color: #666;
      box-shadow: 0 1px 4px #444;
    }

    &.inverted * {
      color: inherit;
    }

    &.mini {
      padding: 4px 10px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.error {
      background: @errorColor;

      &:hover {
        background: darken(@errorColor, 5%);
      }

      &:active {
        background: @errorColor;
      }
    }

    &.info {
      background: @infoColor;

      &:hover {
        background: darken(@infoColor, 5%);
      }

      &:active {
        background: @infoColor;
      }
    }

    &.success {
      background: @successColor;

      &:hover {
        background: darken(@successColor, 5%);
      }

      &:active {
        background: @successColor;
      }
    }

    &:hover {
      background: darken(@mainColor, 5%);
      color: white;
    }

    &:active {
      background: @mainColor;
      box-shadow: none;
      transform: translate(0, 2px);

      &:hover {
        background: @mainColorD;
        box-shadow: inset 0 2px 4px rgb(0 0 0 / 20%);
      }
    }

    &.default {
      min-width: 150px;
    }

    &.special {
      padding: 8px 20px;

      &.fullSize {
        margin: 10px 0;
      }
    }

    &.inline {
      width: auto;
      display: inline-block;
    }

    &.disabled {
      background: #aaa;
      color: #ddd;
      box-shadow: none;
      transform: translate(0, 2px);
      cursor: not-allowed;
    }
  }
}

.aSearchWrapper {
  display: inline-block;

  &,
  .aSearch {
    min-width: 300px;
  }

  .aSearchResultWrapper {
    background: white;
    box-shadow: 0 0 5px #666;
    position: absolute;
    width: 300px;
    max-height: 500px;
    overflow: hidden auto;
    padding: 10px 0;
    z-index: 20;
  }

  .aSearchResult {
    padding: 10px;

    &.selected {
      color: white;
      background: @mainColor;
      cursor: pointer;
    }
  }
}

nav {
  display: table;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;

  &.aCard {
    padding: 0;

    > a {
      display: table-cell;
    }
  }
}

.hover-opacity {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

nav a {
  display: table-cell;
  opacity: 0.75;
  color: #888;
  border-right: 1px solid #ddd;

  &:last-of-type {
    border-right: 0;
  }

  > div {
    padding: 5px 10px 10px;
  }

  &:hover,
  &.active {
    opacity: 1;
    color: @mainColor;
    border-radius: 0;
  }

  &.active,
  &.active:hover {
    opacity: 1;
    background: @mainColor;
    color: white;
    padding: 0;
  }
}

.picture-frame {
  width: 100%;
  height: 180px;
  position: relative;
  padding: 10px 10px 0 0;

  .filetype {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 300%;
    text-align: center;
    position: absolute;
    inset: 0;
    display: block;
    .backgroundLinearGradient(rgba(0,0,0,0), rgba(0,0,0,0.2));

    text-shadow: 0 0 6px #444;
    .transition(background, 0.4s);

    p {
      margin: 40px 0 0;
    }
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px #888;
    padding: 3px 3px 33px;
    background-color: white;

    .caption {
      padding: 5px 30px 5px 5px;
      font-size: 80%;
      line-height: 1;
      vertical-align: middle;
      white-space: nowrap;
      position: relative;
      width: 100%;
      height: 30px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: @mainColor;

      .filetype {
        .backgroundLinearGradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
      }

      .frame-control {
        opacity: 1;
      }

      .caption {
        color: white;
      }
    }
  }

  .picture {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .frame-control {
    position: absolute;
    bottom: 3px;
    right: 3px;
    opacity: 0;
    height: 25px;
    width: 25px;
    .transition(opacity, 0.4s);

    text-align: center;
    border-radius: 100%;
    overflow: hidden;
    padding: 0;
    z-index: 1200;

    img {
      height: 15px;
    }

    a {
      display: block;
      width: 100%;
      color: white;
      text-decoration: none;
      padding: 5px;
      font-weight: normal;

      &:hover {
        color: white;
        background: @errorColor;
      }
    }
  }
}

.main-comment {
  font-weight: 500;
}

.section-comments {
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .section-comments-overlay {
    height: 150px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 30px;

    .section-comments-overlay-gradient {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      height: 10px;
      display: table-cell;
      vertical-align: bottom;
      .backgroundLinearGradient(rgba(255,255,255,0), rgba(255,255,255,1));
    }

    .section-comments-overlay-more {
      background: white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
    }
  }
}

.pagination {
  width: 100%;
  display: table;
  padding: 20px 0;
  text-align: left;

  > div {
    display: table-cell;
  }

  :not(.ui) a,
  :not(.ui) .aButton {
    width: auto;
    min-width: 0;
    background: #888 !important;

    &:hover {
      background: #aaa !important;
    }

    white-space: nowrap;

    &.current {
      .backgroundLinearGradient(lighten(@mainColor, 5%), @mainColor) !important;
    }
  }
}

/* Identification Color Code --- */

.ICC {
  &.color {
    &.error { color: @errorColor; }
    &.info { color: @infoColor; }
    &.success { color: @successColor; }
    &.highlight { color: @highlightColor; }
    &.mainColor { color: @mainColor; }
    &.mainColorD { color: @mainColorD; }
    &.disabled { color: @disabledColor; }
  }

  &.border {
    &.error { border-color: @errorColor; }
    &.info { border-color: @infoColor; }
    &.success { border-color: @successColor; }
    &.highlight { border-color: @highlightColor; }
    &.mainColor { border-color: @mainColor; }
    &.mainColorD { border-color: @mainColorD; }
    &.disabled { border-color: @disabledColor; }
  }

  &.dot {
    display: inline-block;
    width: 18px;
    height: 9px;
    border-radius: 0;
    border: 1px solid white;
    vertical-align: middle;
    margin-top: -1px;
    background: #999;

    .pointer & {
      height: 18px;
      border-radius: 100%;
    }

    &.inline {
      height: 1em;
      width: 1em;
    }
  }

  &.dot,
  &.background {
    > p,
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > a.aButton,
    & {
      color: white;
    }

    > label {
      color: #ddd;
    }

    &.error { background: @errorColor; }
    &.info { background: @infoColor; }
    &.success { background: @successColor; }
    &.highlight { background: @highlightColor; }
    &.mainColor { background: @mainColor; }
    &.mainColorD { background: @mainColorD; }
    &.disabled { background: @disabledColor; }
  }
}

#calendarDiv {
  * {
    box-sizing: border-box;
  }
}

a.pointer .ICC.dot {
  width: 12px;
  height: 12px;
}

.layout-hidden {
  display: none;
}

.calendar-day {
  &.previous,
  &.next {
    background: #bbb;
    color: #fff;

    &:hover {
      background: #ccc;
    }
  }

  &:hover {
    background: fade(@mainColor, 20%);
  }
}

.progress-wrapper {
  width: 100%;
  background: white;
  box-shadow: 0 0 10px #bbb;
  border-radius: 4px;
  height: 32px;
  position: relative;
  overflow: hidden;

  .progress-content {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    line-height: 32px;
    text-align: center;

    &.finished {
      color: white;
    }
  }

  .progress {
    background: @mainColor;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    display: block;

    &.progress-status {
      &-1 {
        background: @successColor;
      }

      &-0 {
        background: @errorColor;
      }

      &--1 {
        background: #444;
      }
    }
  }
}

.customer {
  .aTable {
    width: 100%;
    margin-top: 40px;
    background-color: transparent;

    tr {
      td:nth-child(1) {
        width: 12px;
      }

      td:nth-child(2) {
        width: 270px;
      }

      td:nth-child(3) {
        width: 135px;
      }

      td:nth-child(4) {
        width: 70px;
      }

      td:nth-child(5) {
        width: 75px;
      }

      td:nth-child(6) {
        width: 105px;
      }

      td:nth-child(7) {
        width: 130px;
      }

      a {
        display: inline-block;
        width: 100%;
        cursor: pointer;
      }
    }

    .aButton {
      color: white;
      white-space: nowrap;
    }

    .thead {
      background-color: transparent;

      &:first-child .aTableCell {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      &:nth-child(3) {
        background-color: #f5f5f5;

        .aTableCell {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }

    .tbody {
      background-color: white;

      &:nth-child(2) {
        padding-top: 20px;
        padding-bottom: 15px;
      }
    }
  }

  .aTableRow {
    height: 100%;

    * {
      color: #444;
    }
  }

  .aTableCell {
    height: 100%;
  }

  td.status {
    padding: 0;

    div {
      width: 12px;
      height: 100%;
      box-sizing: border-box;
      border-radius: 6px 0 0 6px;
      padding: 25px 8px;
    }
  }
}

.vue-treeselect,
.vue-treeselect__menu-container {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 110%;
}

.vue-treeselect__label {
  color: black;
}

.evenRow {
  background-color: white;
}

.oddRow {
  background-color: rgb(240 240 240);
}

.building-overview-menu {
  .search-bar {
    float: left;
    padding: 20px 0;

    a {
      white-space: nowrap;
    }
  }

  select {
    margin-left: 30px;
    display: inline;
    width: auto;
  }

  .new {
    margin-left: 30px;
  }

  .pagination {
    float: right;
    text-align: right;
    width: auto;
  }
}

.float-right {
  float: right;
}

.icon-cell {
  vertical-align: text-top;
}

#pdWrapper {
  width: 1000px;
  display: -ms-grid;
  display: grid;
  grid-template:
    "headerBasic headerContractData" 36px
    "basicData contractForm"
    "footer footer" / 350px 640px;
  -ms-grid-rows: 36px max-content max-content;
  -ms-grid-columns: 350px 640px;
  grid-column-gap: 10px;
}

.pdBasicDataHeader {
  grid-area: headerBasic;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  background: @mainColor;
  color: white;
  font-weight: 500;
  margin: 0;
  padding: 10px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.pdContractDataHeader {
  grid-area: headerContractData;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  background: @mainColor;
  color: white;
  font-weight: 500;
  margin: 0;
  padding: 10px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.pdBasicDataForm {
  grid-area: basicData;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  background-color: white;
  margin-top: 0;
  padding-right: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pdBasicDataFormGrid {
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
}

.pdContractDataForm {
  grid-area: contractForm;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  background-color: white;
  margin-top: 0;
  display: -ms-grid;
  display: grid;
  grid-template-areas:
    "innerContractForm buildingInfo"
    "contracts contracts";
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 10px;
}

.pdInnerContractForm {
  grid-area: innerContractForm;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  width: 380px;
  grid-template-columns: 1fr 2fr;
}

.service-link, .ont-link {
  margin-top: 5px;
  position: relative;

  a.edit {
    position: absolute;
    right: -15px;
    top: -1px;
  }

  dl {
    margin-bottom: 0;

    dd, dt {
      display: inline;
      margin: 0;
      position: relative;
    }

    dd::after {
      content: ';';
    }

    dd.break::after {
      content: '';
      display: block;
    }

    dt::after {
      content: ':';
    }

    dd:last-child::after {
      content: '';
    }
  }
}

.pdBuildingInfo {
  grid-area: buildingInfo;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  padding-right: 15px;
}

.pdContracts {
  width: 630px;
  padding-left: 5px;

  .c-task-history {
    word-wrap: anywhere;
  }
}

.pdFooter {
  grid-area: footer;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
}

.pdFormgrid {
  display: grid;
  grid-gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  label, .label {
    font-size: 100%;
  }

  > span {
    padding: 5px 15px;
    vertical-align: middle;
  }

  &.pdBasicDataFormGrid {
    button, input, optgroup, select, textarea {
      margin-left: 0;
    }
  }
}

.pdCheckboxgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.layout.admin > div > a {
  display: block;
}

.layout.admin > div > a.invalid {
  opacity: 0.25;
}

.layout.admin > div .aCard {
  text-align: center;
}

.p-10 {
  padding: 10px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.layout-shortcut > div {
  width: 49% !important;
}

.m-0 {
  margin: 0 !important;
}

.center {
  text-align: center;
}

.vcenter {
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}

.left {
  text-align: left;
}

.save-div {
  text-align: center;
  padding: 10px;
}

.div-center-p20 {
  .fullSize();
  .aTable();

  padding: 20px 40px;
  text-align: center;
}

.img-bottom {
  display: inline-block;
  vertical-align: bottom;
}

.div-center-w32 {
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.div-display-none {
  font-size: 75%;
  overflow: auto;
  display: none;
}

.div-auto-overflow {
  font-size: 75%;
  height: 9em;
  overflow: auto;
}

.table-div-p10 {
  .fullSize();
  .aTable();
  .box();

  padding: 10px;
  margin-top: 10px;
}

.div-inline-p0 {
  padding: 0 10px;
  display: inline-block;
}

.card-div-hover {
  padding: 0;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
}

.a-inline-center {
  display: inline-block;
  text-align: center;
}

.div-visible-overflow {
  overflow: visible !important;
  padding: 10px;
  margin-top: 10px;
}

.div-center-w24 {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.w16-centered {
  width: 16px;
  vertical-align: middle;
  text-align: center;
}

.aTable-full {
  .fullSize();
  .aTable();
}

.p-right-10 {
  padding-right: 10px;
}

.div-flex-50 {
  display: flex;
}

.left-50 {
  flex: 0 0 50%;
}

.right-50 {
  flex: 1;
}

.break-word {
  word-wrap: break-word;
}

.font-size-80 {
  font-size: 80%;
}

.greyedOut {
  background-color: rgb(245 245 245 / 100%);
  opacity: 0.5;
}

.comment-box {
  .fullSize();
  .aTable();

  padding: 10px;
}

.padding-disabled {
  padding: 0 !important;
}

.related-items {
  ul {
    padding-left: 0;
  }

  li {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
  }

  div {
    flex: 1 1 auto;
    margin: 0 0 0 1rem;

    &:first-child {
      margin-left: 0;
      flex: 1 1 15%;
    }

    &.task-excerpt {
      flex: 1 1 75%;
    }

    &.processing-status {
      text-align: center;
      flex: 1 1 10%;
    }
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

ul.tooltip-list {
  margin: 0;
  padding: 0;

  li {
    border: none !important;
    padding: 0 !important;
    list-style-type: disc !important;
    margin-left: 9px;
    font-size: 80%;
  }
}

.list-col-3 {
  column-count: 3;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.danger {
  color: @dangerColor;
}

.background-main-color {
  &:hover {
    background-color: @mainColor;
  }

  background-color: @mainColor;
}

.browser {
  vertical-align: top;
}

.browser-icon {
  margin-left: 5px;

  img {
    float: left;
    width: 28px;
  }

  h2 {
    position: relative;
    left: 10px;
  }
}

.delete-icon {
  height: 15px;
}

.notification {
  padding: 15px;
  position: relative;
}

.badge {
  position: absolute;
  color: white;
  padding: 2px 5px;
  border-radius: 50%;
  background: #888888c2;
  top: 3px;
  right: 4px;
}

.full-width {
  width: 100%;
  .padding-disabled;
}

.date-widget {
  width: 66%;
  margin: 0;
}

.time-widget {
  width: 33%;
  margin: 0;
  float: right;
}

.truncated-div {
  float: left;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-80 {
  min-width: 80% !important;
}

.canvas-img {
  border: 1px solid #000;
  margin-top: 10px;
}

.service-table {
  .aCard {
    margin-top: 7px!important;
  }

  .thead {
    font-weight: 800!important;
  }

  .header-button {
    float: right;
    margin-right: -12px!important;
    margin-top: -2px!important;
  }
}

.ui.color-picker-input {
  width: 50px;
  height: 30px;
  padding: 0;
  border: 0;
  cursor: pointer;
  box-shadow: none;

  &:focus,
  &:active,
  &:hover
  {
    box-shadow: none;
    background-color: white;
  }
}
