@import url("../../var.less");

a.pointer,
.pointer {
  white-space: nowrap;
  border-radius: 0;
  background: #888;
  padding: 2px 7px;
  line-height: 20px;
  font-size: 90%;
  font-weight: normal;
  font-family: @fontMonospaced;
  color: white;
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: transparent;

  b {
    font-family: @fontNormal;
  }

  i.circular.icon {
    font-size: 0.7em;
    line-height: 0.7em;
  }
}

a.pointer {
  &.delete {
    background: none;
    color: @mainColor;

    &:hover {
      color: @mainColorD;
      background: #eee;
    }
  }

  &:hover {
    background: @mainColor;
  }
}

.EntityLink-info {
  margin-left: 7px;
}
