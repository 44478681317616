.ShortcutList {
  &-shortcut {
    background: #888;
    color: white;
    display: inline-block;
    border-radius: 4px;
    padding: 2px 10px;
    margin: 0 4px 4px 0;

    a {
      color: inherit;
      cursor: pointer;
    }

    span {
      padding-left: 10px;
      padding-right: 10px;
      color: #C0C0C0;
    }

    &-icon {
      cursor: pointer;
    }
  }
}
