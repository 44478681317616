.ModalWizard {
  &-header-main {
    color: white;
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      text-align: left;

      button {
        margin-left: 0 !important;
      }
    }

    &-middle {
      text-align: center;
    }

    &-right {
      text-align: right;

      button {
        margin-right: 0 !important;
      }
    }

    .ui.grid {
      width: 100%;
      margin: -1rem 0;
    }
  }

  &-content {
    text-align: center;
    position: relative;

    &--loading::after {
      content: '';
      display: block;
      background: rgb(255 255 255 / 80%);
      z-index: 100;
      position: absolute;
      inset: 0;
    }

    @media only screen and (max-width: @largestTabletScreen) {
      > .ui.steps .step {
        font-size: 0.6rem;
      }
    }
  }
}

@media only screen and (width <= 767.98px) {
  //Space between action buttons and end of modal
  .ui.modal > .actions {
    padding: 1rem !important;
  }

  //hide step title
  .ui.mini.steps .step .icon {
    margin: 0;
  }

  .ui.mini.steps .step .content {
    display: none !important;
  }

  // main header in its own row to have enough space for the step titles
  .ModalWizard {
    &-header-main {
      display: block;
    }

    &-header-separator {
      display: none;
    }
  }

  //fit in the available width
  .ui.steps {
    display: flex;
  }
}
