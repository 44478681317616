@element: "site";
@type: "global";

@import url("../../fomantic-ui/theme.config");
@import url("../../var");

.UserIndicator-block() {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  border-radius: 500rem;
  overflow: hidden;
}

.UserIndicator {
  display: flex;

  --size: 2.3em;
  --font-size: 1em;

  &-icon {
    position: relative;

    --color: @grey;
    --image: none;

    > span {
      position: relative;
      display: inline-block;
      white-space: nowrap;

      &::before {
        content: '';
        border-radius: 500rem;
        display: block;
        padding: 0.25em;
        width: var(--size);
        height: var(--size);
        text-align: center;
        line-height: 1em;
        background-color: var(--color);
      }

      > span {
        .UserIndicator-block();

        &::before {
          content: attr(data-initials);
          color: #fff;
          font-size: var(--font-size);
          display: inline-block;
          font-weight: bold;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &::after {
          .UserIndicator-block();

          content: '';
          background-image: var(--image);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border: 0.01em solid transparent;
        }
      }

      &::after {
        .UserIndicator-block();

        content: '';
        border: 0.02em solid @grey;
      }
    }
  }

  &-name {
    align-self: center;
    margin-left: 0.5em;
  }
}
