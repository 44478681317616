.ui.selection.dropdown {
  display: block;
}

.ui input.search {
  background-color: #eee;
  border: none;
  color: black;
}

.ui.dropdown:not(.button) > .default.text {
  color: #999;
  font-weight: 700;
}
