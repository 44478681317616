.ModalMessage {
  &-actions {
    display: flex;
    justify-content: center;
  }

  &-qr-code {
    width: fit-content;
    display: block;
    margin: auto;

    > * {
      display: block;
      height: 10em;
      width: 10em;
      max-height: 25vh;
      max-width: 25vh;
    }
  }
}
