.AvatarEditor {
  &-overlay {
    position: relative;
    display: inline-block;
    font-size: 3em;
    border-radius: 100em;
    overflow: hidden;
    cursor: pointer;

    .dimmer span {
      font-size: 0.3em !important;
      margin-bottom: 0 !important;
    }
  }

  &-image {
    max-width: 600px;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    text-align: center;

    .UserIndicator {
      font-size: 130px;
      display: inline;
    }
  }
}
