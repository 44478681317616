@mainColor: #080878;
@mainColorC: @mainColor;
@mainColorD: darken(@mainColor, 10%);
@errorColor: #9f0100;
@infoColor: #fbba00;
@successColor: #007500;
@highlightColor: #009fe3;
@borderColor: #ddd;
@cellSpacing: 15px;
@secondaryColor: #ec6707;
@disabledColor: rgb(128,128,128);
@dangerColor: #ed1c24;

@articleWidth: 1024px;
@labelColumnWidth: 30%;

@fontNormal: Roboto, "Helvetica Neue", Helvetica, Arial, "Lucida Sans Unicode", sans-serif;
@fontMonospaced: monospace;

@publicPath: "../../public";
@imagePath: "@{publicPath}/images";

.backgroundLinearGradient(@colorA, @colorB) {
  background: linear-gradient(@colorA, @colorB);
}

.transition(@target, @duration: .4s, @ease: ease, @delay: 0) {
  transition: @target @duration @ease @delay;
}
