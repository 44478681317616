.EntityLink {
  &-reference {
    margin-left: 0.25em;
  }

  .icon {
    &::before {
      content: "\00a0";
      position: relative;
      top: -0.1em;
    }
  }
}
