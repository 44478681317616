.QRSnapshoter {
  --scan-display: none;
  --result-display: none;
  --aspect-padding-top: 56.25%;
  --vertical-space: 28rem;
  --max-width: 100%;

  margin: auto;
  width: 100% !important;

  &--scanning {
    --scan-display: block;
  }

  &--result {
    --result-display: block;

    .QRSnapshoter-video-container {
      padding-bottom: 0;
    }
  }

  > .ui.card {
    width: 100%;
  }

  &-redo {
    margin-bottom: 0.5rem !important;
  }

  &-video {
    overflow: hidden;

    .ui.placeholder {
      max-width: none;

      > .image {
        width: 100%;
        height: 0 !important;
        padding-top: var(--aspect-padding-top);
      }
    }

    &-scale {
      margin: auto;
      margin-top: 0.5rem;
      max-width: var(--max-width);
    }

    &-container {
      position: relative;
      padding-bottom: var(--aspect-padding-top);

      .ui.progress {
        background: rgb(0 0 0 / 50%);
        margin-bottom: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        .bar .progress {
          color: #fff !important;
        }
      }

      .ui.progress, .ui.progress .bar {
        border-radius: 0;
      }
    }

    &-result {
      display: var(--result-display);
    }

    &-scanner {
      display: var(--scan-display);
      position: absolute;
      top: 0;
      width: 100%;
    }

    &-scanner, &-result {
      inset: 0;
      max-width: 100%;

      > video, > img {
        position: relative !important;
        width: 100% !important;
        opacity: 1 !important;
        height: 100% !important;
      }
    }
  }
}

ul.ui.list li::before {
  content: unset;
}

.ui.list > .item > i.icon + .content {
  text-align: left;
}
