<template>
  <div
    class="p-10"
    draggable="false"
    @contextmenu="disableContext"
  >
    <div>
      <a
        class="pointer"
        :href="personUrl"
        target="_blank"
      >
        <b>Person</b> {{ person.id }}
      </a>
      <OpenAccessIcon
        v-if="isOpenAccessCustomer"
        size="large"
        :partner="openAccessPartner"
      />
      {{ fullName }}
    </div>
    <span v-if="hasAdditionalInfo"> (</span>
    <span
      v-if="!person.active"
      class="ICC color error"
    >
      {{ 'inaktiv' }}
    </span>
    <span v-if="!person.active && !!person.wohnung && !person.unit_id">, </span>
    <span v-if="!!person.wohnung && !person.unit_id">
      {{ person.wohnung }}
    </span>
    <span v-if="hasAdditionalInfo">)</span>
  </div>
</template>

<script>
import * as R from 'ramda'
import OpenAccessIcon from '@/stories/atom/OpenAccessIcon.ce.vue'

export default {
  components: { OpenAccessIcon },
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  computed: {
    personUrl () {
      return '/person/' + this.person.id
    },
    fullName () {
      return `${this.person.firma}${this.person.firma ? ',' : ''} ${this.person.vorname} ${this.person.nachname}`
    },
    isOpenAccessCustomer () {
      return !R.isEmpty(this.person.wbci_line_ids)
    },
    openAccessPartner () {
      if (!Array.isArray(this.person.wbci_line_ids)) {
        return null
      }

      let latest = 0
      let latestPartner = null
      for (const wbciLineId of this.person.wbci_line_ids) {
        if (wbciLineId.id > latest) {
          latest = wbciLineId.id
          latestPartner = wbciLineId.open_access_partner
        }
      }

      return latestPartner
    },
    hasAdditionalInfo () {
      return !this.person.active || (!!this.person.wohnung && !this.person.unit_id)
    }
  },
  methods: {
    disableContext (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      return false
    }
  }
}
</script>

<style scoped>
.p-10 {
  cursor: grab;
  user-select: none;
}
</style>
