@element: "site";
@type: "global";

@import url("../../fomantic-ui/theme.config");
@import url("../../var");

.OpenAccessIcon {
  overflow: hidden;
  position: relative;
  margin: 0 !important;

  &::after {
    content: attr(data-partner);
    display: block;
    position: absolute;
    left: 0.5em;
    font-size: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    font-family: monospace;
    color: #fff;
    font-weight: bold;
    width: calc(100% - 1em);
    overflow: hidden;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border: 0.05em solid #fff;
    border-radius: 0.25em;
    background-color: @primaryColor !important;
  }
}

.OpenAccessIcon() {
  position: relative;

  &::after {
    display: none;
  }

  &::before {
    content: '';
    border: none;
    border-radius: 0;
    background-color: transparent !important;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.OpenAccessIcon-image(@image) {
  &::before {
    background-image: url("../../../images/@{image}");
  }
}

@oa: "1und1", "com-in", "telekom", "vitroconnect";

each(@oa, {
  @image: @value;
  .OpenAccessIcon[data-partner=@{value}] {
    .OpenAccessIcon();
    .OpenAccessIcon-image("openaccess/@{image}/medium.svg");

    &.big {
      font-size: 2.4em !important;
    }

    &.mini, &.tiny {
      .OpenAccessIcon-image("openaccess/@{image}/tiny.svg");
    }

    &.small {
      .OpenAccessIcon-image("openaccess/@{image}/small.svg");
    }
  }
})
