.DocumentTag {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;

  &-label {
    margin: 0!important;
  }
}
